import _export from "../internals/export";
import _symbolConstructorDetection from "../internals/symbol-constructor-detection";
import _fails from "../internals/fails";
import _objectGetOwnPropertySymbols from "../internals/object-get-own-property-symbols";
import _toObject from "../internals/to-object";
var $ = _export;
var NATIVE_SYMBOL = _symbolConstructorDetection;
var fails = _fails;
var getOwnPropertySymbolsModule = _objectGetOwnPropertySymbols;
var toObject = _toObject;

// V8 ~ Chrome 38 and 39 `Object.getOwnPropertySymbols` fails on primitives
// https://bugs.chromium.org/p/v8/issues/detail?id=3443
var FORCED = !NATIVE_SYMBOL || fails(function () {
  getOwnPropertySymbolsModule.f(1);
});

// `Object.getOwnPropertySymbols` method
// https://tc39.es/ecma262/#sec-object.getownpropertysymbols
$({
  target: "Object",
  stat: true,
  forced: FORCED
}, {
  getOwnPropertySymbols: function getOwnPropertySymbols(it) {
    var $getOwnPropertySymbols = getOwnPropertySymbolsModule.f;
    return $getOwnPropertySymbols ? $getOwnPropertySymbols(toObject(it)) : [];
  }
});
export default {};