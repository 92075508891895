import _esSymbol from "../modules/es.symbol.constructor";
import _esSymbol2 from "../modules/es.symbol.for";
import _esSymbol3 from "../modules/es.symbol.key-for";
import _esJson from "../modules/es.json.stringify";
import _esObject from "../modules/es.object.get-own-property-symbols";
// TODO: Remove this module from `core-js@4` since it's split to modules listed below
_esSymbol;
_esSymbol2;
_esSymbol3;
_esJson;
_esObject;
export default {};